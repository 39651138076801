import { WalletAdapterNetwork } from "@solana/wallet-adapter-base";
import { clusterApiUrl, Connection, PublicKey } from "@solana/web3.js";

export const network = (process.env.NEXT_PUBLIC_SOLANA_NETWORK ||
  WalletAdapterNetwork.Devnet) as WalletAdapterNetwork;
// const network = WalletAdapterNetwork.Devnet;
export const rpcHost = "https://ssc-dao.genesysgo.net/" || clusterApiUrl(network);

export const candyMachineId = new PublicKey(
  process.env.NEXT_PUBLIC_CANDY_MACHINE_ID ||
    "4Eg728qPAkQ9cvBt4qM113ZmAvzpvRCs42HjWbQBoqka"
);

export const candyMachineIdc2 = new PublicKey(
  process.env.NEXT_PUBLIC_CANDY_MACHINE_ID ||
    "5nUsUk1QPFjegGsMaw4Vdbe74GERGr1wF9KpwvsDGyms"
);

export const candyMachineIdc35 = new PublicKey(
  process.env.NEXT_PUBLIC_CANDY_MACHINE_ID ||
    "3ygG9LHizQMTSWjKo2WyH8d8x94E8QVqFnVXK2pu5W8U"
);

export const candyMachineIdc356 = new PublicKey(
  process.env.NEXT_PUBLIC_CANDY_MACHINE_ID ||
    "4jWJnywHfBfpNrFbDFPKAy9644VqmGb2oKoLwZ7vd4kd"
);


export const candyMachineIdc3 = new PublicKey(
  process.env.NEXT_PUBLIC_CANDY_MACHINE_ID ||
    "F29LyXvW7gDDQzpxnMGZJ8SmcBjN5xqa1s65RWK29nLU"
);






// CtV2qDRtJgXXbB2L9nGDTMc3aYHoTujR4KCTLuwHnRQS
// 9ipfnv13re3xh3iJ87As3LosJ5D8bh4BXsKnQJgqoj96

// https://api.mainnet-beta.solana.com


// https://metaplex.devnet.rpcpool.com/


//6EEXUWCoEjeZMvohrvhXv96JkKN86HeaZrKrfhxLzguX
